@import "_generated-font-mixins.scss";
.blog {
  &__wrapper {
    @media screen and (width <= 1200px) {
      max-width: 940px;
    }

    @media screen and (width <= 992px) {
      max-width: 720px;
    }

    margin: 0 auto;
  }

  &__breadcrumbs {
    margin-bottom: -25px;
  }
}
