@import "_generated-font-mixins.scss";
.clear__button {
  position: absolute;
  top: 0;
  right: 0;

  width: 54px;
  height: 51px;

  background: #ececef;

  &::after {
    @include icon-trash;

    font-size: 22px;
    color: #555;
  }
}

.history {
  &__loading {
    padding: 50px 0;
  }

  &__item {
    position: relative;
    font-size: 14px;
    color: #333;
    list-style-type: none;

    &__link {
      position: relative;

      overflow: hidden;
      display: block;

      padding: 12.5px 25px 12.5px 16px;

      color: inherit;
      text-decoration: none;
      word-wrap: break-word;

      &::after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 1px;

        background: #e6e6e6;
      }
    }

    &__icon {
      position: absolute;
      top: calc(50% - 22.5px);
      right: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 8px;
      height: 45px;

      font-size: 23px;
      line-height: 45px;
      color: #999;

      &::before {
        @include icon-arrow-small-right;

        font-size: 45px;
        color: #999;
      }
    }
  }

  &__empty {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    text-align: center;

    &::after {
      @include icon-history-no-items;

      font-size: 129px;
      color: #f0f1f4;
    }
  }
}
