@import "_generated-font-mixins.scss";
.ads {
  position: relative;

  &__filters {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__breadcrumbs {
    padding: 13px 10px;
    font-size: 12px;
    color: #999;
    background: #fff;
  }

  &__container {
    margin-bottom: 20px;
  }

  &__switcher {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e5e5e5;

    &__map {
      width: 40px;
      height: 44px;
      line-height: 43px;
      text-align: center;

      &::before {
        @include icon-pin;

        width: 18px;
        margin-top: -5px;
        margin-right: -3px;

        font-size: 21px;
        color: #c9c9ca;
      }

      &__active {
        width: 40px;
        height: 44px;
        line-height: 43px;
        text-align: center;

        &::before {
          @include icon-pin;

          width: 18px;
          margin-top: -5px;
          margin-right: -3px;

          font-size: 21px;
          color: #2d82eb;
        }
      }
    }

    &__list {
      width: 40px;
      height: 44px;
      line-height: 43px;
      text-align: center;

      &::before {
        @include icon-my-ads;

        width: 18px;
        margin-top: -5px;
        margin-right: -3px;

        font-size: 21px;
        color: #c9c9ca;
      }

      &__active {
        width: 40px;
        height: 44px;
        line-height: 43px;
        text-align: center;

        &::before {
          @include icon-my-ads;

          width: 18px;
          margin-top: -5px;
          margin-right: -3px;

          font-size: 21px;
          color: #2d82eb;
        }
      }
    }
  }

  &__empty__search {
    width: 100%;
    margin-top: 73px;
    color: #999;
    text-align: center;

    &__text {
      margin-bottom: 3px;
      font-size: 18px;
    }
  }
}

.map {
  width: 100%;
  height: calc(100vh - 170px);
}
