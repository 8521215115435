@import "_generated-font-mixins.scss";
/* stylelint-disable selector-class-pattern */
.history {
  &__empty {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: row;

    min-height: 70px;
    padding: 15px 25px;

    border-bottom: solid 1px #e2e2e2;

    &__date {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: space-between;

      max-width: 57%;

      font-size: 14px;
      color: #999;
    }

    &__title {
      margin-bottom: 15px;
      font-size: 16px;
      word-wrap: break-word;
    }

    &__link {
      margin-bottom: 15px;

      font-size: 16px;
      font-weight: 700;
      color: #333;
      text-decoration: underline;
      word-wrap: break-word;
    }

    &__type {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      width: 141px;
      padding: 10px;

      &__title {
        font-size: 12px;
        color: #555;
        text-align: center;
      }
    }
  }
}

.icon {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
}

.event_ad_add,
.event_ad_edit,
.event_ad_vip_add,
.event_ad_vip_edit {
  &::after {
    @include icon-ad-edit;

    font-size: 40px;
    color: #555;
  }
}

.event_ad_pay,
.event_ad_vip_pay {
  &::after {
    @include icon-ad-pay;

    font-size: 24px;
    color: #555;
  }
}

.event_ad_show {
  &::after {
    @include icon-ad-show;

    font-size: 40px;
    color: #555;
  }
}

.event_ad_hide,
.event_ad_vip_hide {
  &::after {
    @include icon-ad-hide;

    font-size: 40px;
    color: #555;
  }
}

.event_ad_remove,
.event_ad_vip_remove {
  &::after {
    @include icon-trash;

    font-size: 18px;
    color: #555;
  }
}

.event_ad_block,
.event_ad_expire,
.event_ad_vip_expire {
  width: 40px;
  height: 40px;
  background-image: url('../../../../../public/static/images/ad_block.svg');
}

.event_ad_block_transfer {
  &::after {
    @include icon-ad-block-transfer;

    font-size: 40px;
    color: #555;
  }
}

.event_ad_republish {
  &::after {
    @include icon-ad-republish;

    font-size: 40px;
    color: #609dd1;
  }
}

.event_ad_republish_free {
  &::after {
    @include icon-ad-republish-free;

    font-size: 20px;
    color: #609dd1;
  }
}

.event_ad_autorepublish {
  &::after {
    @include icon-history-view;

    font-size: 24px;
    color: #aac543;
  }
}

.event_ad_colorize {
  &::after {
    @include icon-ad-colorize;

    font-size: 40px;
    color: #e86237;
  }
}

.event_ad_autorepublisher_on {
  &::after {
    @include icon-history-view;

    font-size: 24px;
    color: #aac543;
  }
}

.event_ad_autorepublisher_off,
.event_ad_autorepublisher_settings {
  &::after {
    @include icon-history-view;

    font-size: 24px;
    color: #555;
  }
}

.event_contact_add {
  &::after {
    @include icon-phone;

    font-size: 24px;
    color: #555;
  }
}

.event_contact_delete_admin,
.event_contact_delete_user {
  &::after {
    @include icon-contact-delete-user;

    font-size: 40px;
    color: #555;
  }
}

.event_remove_account_request {
  &::after {
    @include icon-remove-account-request;

    font-size: 24px;
    color: #e86237;
  }
}

.event_draft_add {
  &::after {
    @include icon-draft-add;

    font-size: 24px;
    color: #aac543;
  }
}

.event_draft_edit {
  &::after {
    @include icon-draft-add;

    font-size: 24px;
    color: #ffaf28;
  }
}

.event_draft_remove {
  &::after {
    @include icon-draft-add;

    font-size: 24px;
    color: #e86237;
  }
}

.event_buy_label {
  width: 40px;
  height: 40px;
  background-image: url('../../../../../public/static/images/buy_label.svg');
}

.event_cancel_label {
  width: 40px;
  height: 40px;
  background-image: url('../../../../../public/static/images/cancel_label.svg');
}

.event_change_label {
  width: 40px;
  height: 40px;
  background-image: url('../../../../../public/static/images/change_label.svg');
}

.event_buy_wide_card {
  &::after {
    @include icon-widecard;

    font-size: 24px;
    color: #aac543;
  }
}

.event_cancel_wide_card {
  &::after {
    @include icon-widecard;

    font-size: 24px;
    color: #e86237;
  }
}

.event_buy_frame,
.event_buy_animation,
.event_buy_animation_and_frame {
  &::after {
    @include icon-frame;

    font-size: 24px;
    color: #aac543;
  }
}

.event_cancel_frame,
.event_cancel_animation,
.event_cancel_animation_and_frame {
  &::after {
    @include icon-frame;

    font-size: 24px;
    color: #e86237;
  }
}

.event_subscription_create,
.event_subscription_create_search_query,
.event_subscription_create_user_ads,
.event_subscription_create_ad_price_change,
.event_subscription_create_company_ads {
  &::after {
    @include icon-notifications;

    font-size: 24px;
    color: #f9cc81;
  }
}

.event_subscription_remove,
.event_subscription_remove_search_query,
.event_subscription_remove_user_ads,
.event_subscription_remove_ad_price_change,
.event_subscription_remove_company_ads {
  &::after {
    @include icon-subscription-remove;

    font-size: 45px;
    color: #f9cc81;
  }
}

.event_booster_create,
.event_booster_edit_settings {
  &::after {
    @include icon-booster;

    font-size: 24px;
    color: #035db8;
  }
}

.event_booster_stop {
  transform: rotate(180deg);

  &::after {
    @include icon-booster;

    font-size: 24px;
    color: #035db8;
  }
}

.event_type_cancel_ad_package_lite,
.event_type_expire_ad_package_lite {
  &::after {
    @include icon-lite-package;

    font-size: 40px;
    color: #bbb;
  }
}

.event_type_buy_ad_package_lite {
  &::after {
    @include icon-lite-package;

    font-size: 40px;
    color: #ffc253;
  }
}

.event_type_cancel_ad_package_plus,
.event_type_expire_ad_package_plus {
  &::after {
    @include icon-plus-package;

    font-size: 40px;
    color: #bbb;
  }
}

.event_type_buy_ad_package_plus {
  &::after {
    @include icon-plus-package;

    font-size: 40px;
    color: #c1c885;
  }
}

.event_type_cancel_ad_package_turbo,
.event_type_expire_ad_package_turbo {
  &::after {
    @include icon-turbo-package;

    font-size: 40px;
    color: #bbb;
  }
}

.event_type_buy_ad_package_turbo {
  &::after {
    @include icon-turbo-package;

    font-size: 40px;
    color: #e24f58;
  }
}
