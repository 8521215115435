@import "_generated-font-mixins.scss";
.favorites__page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);

  &__container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 0 12px;
  }

  &__search__wrap {
    background: #ececef;
  }
}
