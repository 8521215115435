@import "_generated-font-mixins.scss";
.clear__button {
  position: absolute;
  top: 0;
  right: 0;

  width: 54px;
  height: 51px;

  &::after {
    @include icon-trash;

    font-size: 22px;
    color: #555;
  }
}

.history {
  &__list {
    overflow: hidden;
    display: flex;

    // flex: 1 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 0 10px;
  }

  &__item {
    position: relative;

    overflow: hidden;

    width: 48%;
    margin-bottom: 20px;

    background: #fff;

    &__thumbnail {
      position: relative;

      overflow: hidden;
      display: block;

      width: 100%;
      height: 129px;
      margin-bottom: 10px;

      font-size: 0;
      text-align: center;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__body {
      position: relative;
      text-align: left;
    }

    &__title {
      overflow: hidden;

      width: 100%;
      max-height: 54px;
      margin-bottom: 5px;

      font-size: 15px;
      line-height: 18px;
      hyphens: auto;
      color: #333;
      text-decoration: none;
      word-break: break-word;

      &:visited {
        color: #999;
      }
    }

    &__price {
      font-size: 15px;
      font-weight: 700;
      line-height: 16px;
      color: #333;
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;
    }
  }

  &__empty {
    position: relative;
    display: flex;
    flex-flow: column wrap;
  }

  &__empty__icon {
    text-align: center;

    &::after {
      @include icon-history-no-items;

      font-size: 129px;
      color: #f0f1f4;
    }
  }
}
