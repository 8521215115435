@import "_generated-font-mixins.scss";
.navigation__block {
  display: flex;
  justify-content: center;
  padding: 20px 5px;
  text-align: center;
}

.pagination__mobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 85%;
}

.icon__prev,
.icon__next {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 33px;
  height: 75%;
  padding: 10px 9px;

  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.icon__prev {
  &::after {
    @include icon-arrow-navigation-left;
  }
}

.icon__next {
  &::before {
    @include icon-arrow-navigation-right;
  }
}

.pagination__text {
  margin: 0 2px;
  font-size: 14px;
  line-height: 16px;
  color: #333;
}
